<template>
    <layout-admin-dashboard-view
    :showTitleBackLink="false">
        <template v-slot:title>
           {{translateTitleCase('admin.dashboard.dashboardTitle')}}
        </template>

        <template v-slot:mainContent>
            <block>
                <div class="uk-child-width-1-2@s uk-child-width-1-4@l" uk-grid uk-height-match="target: > div > .uk-card">
                    <block v-for="link of links" :key="link" :link="link">
                        <router-link
                                :to="link.to"
                                custom
                                v-slot="{ navigate, href, route }">
                            <div class="uk-card uk-card-default uk-card-body admin-item-link  uk-card-hover"
                                 @click="navigate">
                                <h3 class="uk-card-title">
                                    <inline :uk-icon="link.icon"></inline>
                                    <inline>{{translateTitleCase(link.label)}}</inline>
                                </h3>
                                <p>{{translateSafe(link.description)}}</p>
                            </div>
                        </router-link>
                    </block>
                </div>



            </block>
        </template>
    </layout-admin-dashboard-view>
</template>

<script>
    import { useMeta } from 'vue-meta'
    import {useI18n} from "vue-i18n/index";

    export default {
        setup () {
            let {t} = useI18n();
            let {meta} = useMeta( {
                'title': t('admin.dashboard.title'),
            });
            //meta.sitename.content = 'Saffron Admin';

            return {meta};
        },
        data: function () {
            return {
                links: [
                    {
                        to: {name:'entity-dashboard'},
                        icon: 'copy',
                        shortLabel: 'admin.entity.titleShort',
                        label: 'admin.entity.title',
                        description: 'admin.entity.description',
                    },
                    {
                      to: {name: 'admin-user'},
                      icon: 'user',
                      shortLabel: 'admin.user.titleShort',
                      label: 'admin.user.title',
                      description: 'admin.user.description',
                    },
                    {
                        to: {name:'admin-language'},
                        icon: 'world',
                        shortLabel: 'admin.language.titleShort',
                        label: 'admin.language.title',
                        description: 'admin.language.description',
                    },
                ]
            };
        },
        created() {
         //   console.log(this.meta);
        }
    }
</script>

<style scoped lang="scss">

    .admin-item-link {
        cursor: pointer;

        .uk-card-title{
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .uk-icon {
                position: relative;
                margin-right: 4px;
               // top: 0px;
            }
        }
    }


</style>
